import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/home/home.vue'
import Header from '../components/header.vue';

Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Digital Native Solutions | Technology and IT Recruitment Canberra' }
  },
  {
    path: '/company/about-us',
    name: 'About Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "about-us" */ '../views/company/about-us.vue'),
      header: Header
    },
    meta: { title: 'About Us | Digital Native Solutions' }
  },
  {
    path: '/company/our-approach',
    name: 'Our Approach',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "our-approach" */ '../views/company/our-approach.vue'),
      header: Header
    },
    meta: { title: 'Our Approach | Digital Native Solutions' }
  },  
  {
    path: '/services/software-development/web-applications',
    name: 'Web applications',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "web-applications" */ '../views/services/software-development/web-applications.vue'),
      header: Header
    },
    meta: { title: 'Web applications | Digital Native Solutions' }
  },
  {
    path: '/services/software-development/mobile-development',
    name: 'Mobile development',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "mobile-development" */ '../views/services/software-development/mobile-development.vue'),
      header: Header
    },
    meta: { title: 'Mobile development | Digital Native Solutions' }
  },
  {
    path: '/services/software-development/frontend-development',
    name: 'Frontend Development',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "frontend-development" */ '../views/services/software-development/frontend-development.vue'),
      header: Header
    },
    meta: { title: 'Frontend Development | Digital Native Solutions' }
  },
  {
    path: '/services/systems-integration/system-design',
    name: 'System design',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "system-design" */ '../views/services/systems-integration/system-design.vue'),
      header: Header
    },
    meta: { title: 'System design | Digital Native Solutions' }
  },
  {
    path: '/services/systems-integration/cloud-architecture',
    name: 'Cloud architecture',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "cloud-architecture" */ '../views/services/systems-integration/cloud-architecture.vue'),
      header: Header
    },
    meta: { title: 'Cloud architecture | Digital Native Solutions' }
  },
  {
    path: '/services/systems-integration/penetration-testing',
    name: 'Penetration testing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "penetration-testing" */ '../views/services/systems-integration/penetration-testing.vue'),
      header: Header
    },
    meta: { title: 'Penetration testing | Digital Native Solutions' }
  },
  {
    path: '/services/systems-automation/dev-sec-ops',
    name: 'DevSecOps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "dev-ops" */ '../views/services/systems-automation/dev-sec-ops.vue'),
      header: Header
    },
    meta: { title: 'DevSecOps | Digital Native Solutions' } 
  },
  {
    path: '/services/systems-automation/continous-deployment',
    name: 'Continous deployment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "continous-deployment" */ '../views/services/systems-automation/continous-deployment.vue'),
      header: Header
    },
    meta: { title: 'Continous deployment | Digital Native Solutions' } 
  },
  {
    path: '/services/systems-automation/e2e-testing',
    name: 'E2E testing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "e2e-testing" */ '../views/services/systems-automation/e2e-testing.vue'),
      header: Header
    },
    meta: { title: 'E2E testing | Digital Native Solutions' } 
  },
  {
    path: '/services/professional-services/recruitment',
    name: 'Recruitment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "payroll" */ '../views/services/professional-services/recruitment.vue'),
      header: Header
    },
    meta: { title: 'Recruitment | Digital Native Solutions' } 
  },
  {
    path: '/services/professional-services/job-listings',
    name: 'Job Listings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "job-listings" */ '../views/services/professional-services/job-listings.vue'),
      header: Header
    },
    meta: { title: 'Job Listings | Digital Native Solutions' } 
  },
  {
    path: '/services/professional-services/subscribe',
    name: 'Subscribe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "subscribe" */ '../views/services/professional-services/subscribe.vue'),
      header: Header
    },
    meta: { title: 'Subscribe Job Listings | Digital Native Solutions' } 
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "contact" */ '../views/contact/contact.vue'),
      header: Header
    },
    meta: { title: 'Contact | Digital Native Solutions' } 
  },
  {
    path: '/blog',
    name: 'Blog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "blog" */ '../views/blog/blog.vue'),
      header: Header
    },
    meta: { title: 'Blog | Digital Native Solutions' } 
  },
  {
    path: '/blog/posts/choosing-the-right-cms-for-your-enterprise',
    name: 'Choosing the right CMS for your Enterprise',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "choosing-the-right-cms-for-your-enterprise" */ '../views/blog/posts/choosing-the-right-cms-for-your-enterprise.vue'),
      header: Header
    },
    meta: { title: 'Choosing the right CMS for your Enterprise | Blog | Digital Native Solutions' } 
  },
  {
    path: '/blog/posts/dotnet-cms-showdown',
    name: '.NET CMS Showdown',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "choosing-the-right-cms-for-your-enterprise" */ '../views/blog/posts/dotnet-cms-showdown.vue'),
      header: Header
    },
    meta: { title: '.NET CMS Showdown | Blog | Digital Native Solutions' } 
  },
  {
    path: '/blog/posts/the-need-for-devsecops',
    name: 'The need for DevSecOps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default:() => import(/* webpackChunkName: "the-need-for-devsecops" */ '../views/blog/posts/the-need-for-devsecops.vue'),
      header: Header
    },
    meta: { title: 'The need for DevSecOps | Blog | Digital Native Solutions' } 
  }  
  
]

const DEFAULT_TITLE = 'Digital Native Solutions';

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
      document.title = to.meta?.title || DEFAULT_TITLE;
    });
    var myWindow = window as any;
    if (myWindow.init) {
        myWindow.init();
    }
});

export default router
