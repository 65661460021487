export default new class Data {

    public NavigationItems: Array<any> = [
        {
            title: 'Home',
            href: '/',
            type: 'menu'
        },
        {
            title: 'Company',
            href: '/company/about-us',
            type: 'menu',
            showSubNav: true,
            subNav: [{
                title: 'About Us',
                subTitle: "Innovation is in our DNA",
                href: '/company/about-us',                
            },
            {
                title: 'Our Approach',
                subTitle: 'Find out how we operate',
                href: '/company/our-approach'
            }]
        },
        {
            title: 'Services',
            href: '/services/software-development/web-applications',
            showSubNav: true,
            type: 'mega-menu',
            subNav: [{
                title: 'Software Development',
                href: '/services/software-development/web-applications',
                showSubNav: true,
                subNav: [{
                    title: 'Web Applications',
                    subTitle: 'Make your presence known with Digital Native',
                    href: '/services/software-development/web-applications'
                },
                {
                    title: 'Mobile Development',
                    subTitle: 'Cross platform solutions',
                    href: '/services/software-development/mobile-development'
                },
                {
                    title: 'Frontend Development',
                    subTitle: 'Use the right technology for the right job',
                    href: '/services/software-development/frontend-development'
                }]
            },
            {
                title: 'Systems Integration',
                href: '/services/systems-integration/system-design',
                showSubNav: true,
                subNav: [{
                    title: 'System Design',
                    subTitle: 'Understand were your headed',
                    href: '/services/systems-integration/system-design'
                },
                {
                    title: 'Cloud Architecture',
                    subTitle: 'Understand your possibilities',
                    href: '/services/systems-integration/cloud-architecture'
                },
                {
                    title: 'Penetration Testing',
                    subTitle: 'Understand the risks',
                    href: '/services/systems-integration/penetration-testing'
                }]
            },
            {
                title: 'Systems Automation',
                href: '/services/systems-automation/dev-sec-ops',
                showSubNav: true,
                subNav: [{
                    title: 'DevSecOps',
                    subTitle: 'Deploy to production every day',
                    href: '/services/systems-automation/dev-sec-ops'
                },
                {
                    title: 'Continous Deployment',
                    displayTitle: 'CI/CD Pipelines',
                    subTitle: 'Automate your way to success',
                    href: '/services/systems-automation/continous-deployment'
                },
                {                    
                    title: 'E2E Testing',
                    displayTitle: 'End to End Testing',
                    subTitle: 'Be confidant in your approach',
                    href: '/services/systems-automation/e2e-testing'
                }]
            },
            {
                title: 'Professional Services',
                href: '/services/professional-services/recruitment',
                showSubNav: true,
                subNav: [{
                    title: 'Recruitment',
                    subTitle: 'Boutique Professional Services house specialising within the IT local market',
                    href: '/services/professional-services/recruitment'
                },
                {
                    title: 'Job Listings',
                    subTitle: 'Find out whats available',
                    href: '/services/professional-services/job-listings'
                },
                {
                    title: 'Subscribe',
                    subTitle: 'Keep up-to-date with whats available',
                    href: '/services/professional-services/subscribe'
                }]
            }]
        },           
        {
            title: 'Contact',
            displayTitle: 'Contact Us',
            subTitle: 'Get in touch with us',
            href: '/contact',
            type: 'menu',
        },
        {
            title: 'Blog',
            displayTitle: 'Digital Native Blog',
            subTitle: 'Whats the latest',
            href: '/blog',
            type: 'menu',
            showSubNav: false,
            subNav: [{
                title: 'Azure cloud adoption for small businesses',
                href: '/blog/posts/azure-cloud-adoption-for-small-businesses'                          
            },
            {                
                title: 'Choosing the right CMS for your Enterprise',
                href: '/blog/posts/choosing-the-right-cms-for-your-enterprise',
            },
            {                
                title: '.NET CMS Showdown',
                href: '/blog/posts/dotnet-cms-showdown',
            },
            {                
                title: 'The need for DevSecOps',
                href: '/blog/posts/the-need-for-devsecops',                
            },
            {                
                title: 'SPA development for the Enterprise',
                href: '/blog/posts/spa-development-for-the-enterprise',               
            }]
        }
    ]

    public CompanyEmail: string = "info@digitalnativesolutions.com.au"
    public CompanySlogan: string = "Technology is our first language"
}
