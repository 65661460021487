
import { Component, Vue } from "vue-property-decorator";
import TopNavigation from "./components/top-navigation.vue";
import TheFooter from "./components/the-footer.vue";
import CallToActionBanner from "./components/call-to-action-banner.vue";

@Component({
  components: {
    TopNavigation,
    TheFooter,
    CallToActionBanner
  },
})
export default class App extends Vue {}
