
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Route } from 'vue-router';
import Data from "../services/data";

@Component({})
export default class TheFooter extends Vue {
  public navItems: Array<any> = Data.NavigationItems;
  public breadcrumbs: Array<any> = [{ title: "Home", link: "/" }];
  public currentNavItem: any = {};

 
  created() {
    this.generateBreadcrumbs();
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
       this.generateBreadcrumbs();
  }

  get formattedTitle(){
    var selectedTitle = this.currentNavItem.displayTitle != null ? this.currentNavItem.displayTitle : this.currentNavItem.title;
    var words = selectedTitle.split(' ');
    var lastWord = words[words.length - 1];
    words[words.length - 1] = `<strong>${lastWord}</string>`
    return words.join(' ');
  }

  generateBreadcrumbs() {
    this.breadcrumbs = [{ title: "Home", link: "/" }];
    var currentRouteName = this.$route.name;
    if (currentRouteName != null) {
      var paths = this.findCurrentBreadcrumb(
        currentRouteName,
        Data.NavigationItems
      );
      var converted = paths
        .map((segment, index) => {
          return {
            title: segment.title,
            link: segment.href,
            isActive: index == 0,
          };
        })
        .reverse();
      this.breadcrumbs.push(...converted);
      this.currentNavItem = paths[0];
    }
  }

  findCurrentBreadcrumb(
    currentRouteName: string,
    items: Array<any>
  ): Array<any> {
    var crumbs: Array<any> = [];

    for (var i = 0; i < items.length; i++) {
      var currentItem = items[i];

      if (currentItem.title.toLowerCase() == currentRouteName.toLowerCase()) {
        crumbs.push(currentItem);
        break;
      }

      if (currentItem.subNav != null) {
        var isFound = this.findCurrentBreadcrumb(
          currentRouteName,
          currentItem.subNav
        );
        if (isFound.length > 0) {
          crumbs.push(...isFound);
          crumbs.push(currentItem);
          break;
        }
      }
    }

    return crumbs;
  }
}
