<template>
  <div>
    <div class="slider-container rev_slider_wrapper" style="height: 100vh;">
      <div
        id="revolutionSlider"
        class="slider rev_slider cover"
        data-version="5.4.8"
        data-plugin-revolution-slider
        data-plugin-options="{'sliderLayout': 'fullscreen', 'delay': 0, 'gridwidth': [1410,1110,930,690], 'gridheight': 700, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1422,1182,974], 'navigation' : {'arrows': { 'enable': true, 'style': 'arrows-style-1 arrows-primary' }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}"
      >
        <ul>
          <li class="slide-overlay ">        

            <div
              class="rs-background-video-layer "
             data-forcerewind="on"
              data-volume="mute"
              data-videowidth="100%"
              data-videoheight="100%"
              data-videomp4="/intro2.mp4"
              data-videopreload="preload"
              data-force-cover="1"
              data-aspectratio="16:9"
              data-autoplay="true"
              data-autoplayonlyfirsttime="false"
              data-nextslideatend="false"
              data-videoloop="loop"
             
            ></div>

             <!-- data-forcerewind="on"
              data-volume="mute"
              data-videowidth="100%"
              data-videoheight="100%"
              data-videomp4="/proto/video/intro2.mp4"
              data-videopreload="preload"
              data-force-cover="1"
              data-aspectratio="16:9"
              data-autoplay="true"
              data-autoplayonlyfirsttime="false"
              data-nextslideatend="false"
              data-videoloop="loop" -->
          </li>
        </ul>
      </div>
    </div>

    <div class="container container-lg py-5 my-5">
      <div class="row justify-content-center">
        <div class="col-xl-9 text-center">
          <h2
            class="font-weight-bold text-11 appear-animation"
            data-appear-animation="fadeInUpShorter"
          >Technology is our first language</h2>
          <p
            class="line-height-9 text-4 opacity-9 appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="200"
          >Digital Native is a boutique full stack software design agency that has a passion for delivery, we specialise in Design, Development, Architecture and Professional Services letting you focus on what you do best, your business.</p>
        </div>
      </div>
      <div class="row featured-boxes featured-boxes-style-4">
        <div
          class="col-sm-6 col-lg-3 appear-animation"
          data-appear-animation="fadeInLeftShorter"
          data-appear-animation-delay="400"
        >
          <div class="featured-box mb-lg-0">
            <div class="box-content px-lg-1 px-xl-5">
              <i class="icon-featured icons icon-screen-desktop text-color-primary text-11"></i>
              <h4 class="font-weight-bold text-5 mb-3">Software Development</h4>
              <p>Mobile, Web and Native solutions for any business need.</p>
            </div>
          </div>
        </div>
        <div
          class="col-sm-6 col-lg-3 appear-animation"
          data-appear-animation="fadeInLeftShorter"
          data-appear-animation-delay="200"
        >
          <div class="featured-box mb-lg-0">
            <div class="box-content px-lg-1 px-xl-5">
              <i class="icon-featured icons icon-organization text-color-primary text-11"></i>
              <h4 class="font-weight-bold text-5 mb-3">Systems Integration</h4>
              <p>Architecture/System Design for both cloud and on premise solutions.</p>
            </div>
          </div>
        </div>
        <div
          class="col-sm-6 col-lg-3 appear-animation"
          data-appear-animation="fadeInRightShorter"
          data-appear-animation-delay="200"
        >
          <div class="featured-box mb-sm-0">
            <div class="box-content px-lg-1 px-xl-5">
              <i class="icon-featured icons icon-social-dropbox text-color-primary text-11"></i>
              <h4 class="font-weight-bold text-5 mb-3">Systems Automation</h4>
              <p>Embracing Dev/Sec/Ops to help push the corporate culture.</p>
            </div>
          </div>
        </div>
        <div
          class="col-sm-6 col-lg-3 appear-animation"
          data-appear-animation="fadeInRightShorter"
          data-appear-animation-delay="400"
        >
          <div class="featured-box mb-0">
            <div class="box-content px-lg-1 px-xl-5">
              <i class="icon-featured icons icon-briefcase text-color-primary text-11"></i>
              <h4 class="font-weight-bold text-5 mb-3">Professional Services</h4>
              <p>A boutique Professional Services house specialising within the IT local market.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section border-0 pt-0 pt-md-5 m-0">
      <div class="container">
        <div class="row align-items-center justify-content-center pb-4 pb-lg-0">
          <div
            class="col-9 col-lg-6 order-1 order-lg-1 scale-5 pb-5 pb-lg-0 mt-0 mt-md-4 mb-5 mb-lg-0"
          >
            <img class="img-fluid" src="../../assets/proto/img/screen.png" />
          </div>
          <div class="col-lg-6 order-2 order-lg-2 pr-5 pt-4 pt-lg-0 mt-md-5 mt-lg-0">
            <span class="font-weight-bold text-color-dark opacity-8 text-4">SOFTWARE DEVELOPMENT</span>
            <h2 class="font-weight-bold text-9 mb-4">Mobile and Web Development</h2>
            <ul class="list list-icons pb-2 mb-4">
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Native/Hybrid mobile app development</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">SPA design and architecture (Angular, React, Vue)</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Focus on Agile delivery</span>
              </li>
            </ul>
            <router-link
              class="btn btn-primary font-weight-semibold rounded btn-px-5 py-3 text-2"
              :to="{ name: 'Web applications'}"
            >LEARN MORE</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section border-0 bg-color-light pt-0 pt-md-5 m-0">
      <div class="container">
        <div class="row align-items-center justify-content-center pb-4 pb-lg-0">
          <div class="col-lg-6 order-2 order-lg-1 pr-5 pt-4 pt-lg-0 mt-md-5 mt-lg-0">
            <span class="font-weight-bold text-color-dark opacity-8 text-4">SYSTEMS INTEGRATION</span>
            <h2 class="font-weight-bold text-9 mb-4">Cloud Transformation</h2>
            <ul class="list list-icons pb-2 mb-4">
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Scale your business to meet the needs of the future</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Secure designs that meet industry standards</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Upskill your in-house development team for cloud adoption</span>
              </li>
            </ul>
            <router-link
              class="btn btn-primary font-weight-semibold rounded btn-px-5 py-3 text-2"
              :to="{ name: 'Cloud architecture'}"
            >LEARN MORE</router-link>
          </div>
          <div
            class="col-9 col-lg-6 order-1 order-lg-2 scale-5 pb-5 pb-lg-0 mt-0 mt-md-4 mb-5 mb-lg-0"
          >
            <img class="img-fluid mx-auto d-block" src="../../assets/proto/img/man.png" />
          </div>
        </div>
      </div>
    </section>

    <section class="section border-0 pt-0 pt-md-5 m-0">
      <div class="container">
        <div class="row align-items-center justify-content-center pb-4 pb-lg-0">
          <div
            class="col-9 col-lg-6 order-1 order-lg-1 scale-6 pb-5 pb-lg-0 mt-0 mt-md-4 mb-5 mb-lg-0"
          >
            <img class="img-fluid" src="../../assets/proto/img/automate.png" />
          </div>
          <div class="col-lg-6 order-2 order-lg-2 pr-5 pt-4 pt-lg-0 mt-md-5 mt-lg-0">
            <span class="font-weight-bold text-color-dark opacity-8 text-4">SYSTEMS AUTOMATION</span>
            <h2 class="font-weight-bold text-9 mb-4">Automate business pipelines</h2>
            <ul class="list list-icons pb-2 mb-4">
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Build agility within your corporate culture</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span
                  class="text-4"
                >Develop trust in your release piplines through End-to-end testing</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Deliver to production every day</span>
              </li>
            </ul>
            <router-link
              class="btn btn-primary font-weight-semibold rounded btn-px-5 py-3 text-2"
              :to="{ name: 'Dev Ops'}"
            >LEARN MORE</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="section border-0 bg-color-light pt-0 pt-md-5 m-0">
      <div class="container">
        <div class="row align-items-center justify-content-center pb-4 pb-lg-0">
          <div class="col-lg-6 order-2 order-lg-1 pr-5 pt-4 pt-lg-0 mt-md-5 mt-lg-0">
            <span class="font-weight-bold text-color-dark opacity-8 text-4">PROFESSIONAL SERVICES</span>
            <h2 class="font-weight-bold text-9 mb-4">Boutique IT agency</h2>
            <ul class="list list-icons pb-2 mb-4">
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">100% transparency</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Focused on the local IT market</span>
              </li>
              <li>
                <i class="fas fa-caret-right top-6"></i>
                <span class="text-4">Setup by specialists for specialists</span>
              </li>
            </ul>
            <router-link
              class="btn btn-primary font-weight-semibold rounded btn-px-5 py-3 text-2"
              :to="{ name: 'Recruitment'}"
            >LEARN MORE</router-link>
          </div>
          <div
            class="col-9 col-lg-6 order-1 order-lg-2 scale-6 pb-5 pb-lg-0 mt-0 mt-md-4 mb-5 mb-lg-0"
          >
            <img class="img-fluid mx-auto d-block" src="../../assets/proto/img/rocket.png" />
          </div>
        </div>
      </div>
    </section>

    <section class="section section-height-3 bg-color-dark border-0 m-0">
      <div class="container container-lg">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 col-xl-6 mb-5 mb-lg-0">
            <span
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay="300"
              class="d-block custom-text-color-light-2 custom-heading-bar custom-heading-bar-with-padding font-weight-light text-5 mb-3 appear-animation animated fadeInUpShorter appear-animation-visible"
              style="animation-delay: 300ms;"
            >Let’s Get in Touch</span>
            <h2
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay="500"
              class="text-color-light font-weight-extra-bold text-10 negative-ls-1 pr-3 mb-3 appear-animation animated fadeInUpShorter appear-animation-visible"
              style="animation-delay: 500ms;"
            >LET'S TALK ABOUT YOUR BUSINESS IT SERVICES NEEDS</h2>
            <p
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay="700"
              class="custom-font-secondary text-4 custom-text-color-light-3 mb-0 appear-animation animated fadeInUpShorter appear-animation-visible"
              style="animation-delay: 700ms;"
            >Go native today.</p>
          </div>
          <div class="col-lg-4 col-xl-3">
            <div class="overflow-hidden">
              <router-link
                class="btn btn-primary btn-outline text-color-light font-weight-semibold border-width-4 custom-link-effect-1 text-1 text-xl-3 d-inline-flex align-items-center px-4 py-3 appear-animation animated maskRight appear-animation-visible"
                to="/contact"
              >
                GET STARTED NOW
                <i class="custom-arrow-icon ml-2"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container container-lg">
      <div class="row pt-3 mt-4">
        <div class="col text-center">
          <span class="font-weight-bold text-color-dark opacity-8 text-4">OUR BLOG</span>
          <h2 class="font-weight-semibold text-9 mb-3">What's happening</h2>
          <p class="text-4">Find out about the latest technicial trends within the industry</p>
        </div>
      </div>
      <div class="row justify-content-center pb-3 pt-4 mb-5">
        <div
          class="col-md-7 col-lg-4 pr-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="450"
        >
          <article class="card custom-post-style-1 border-0">
            <header class="overlay overlay-show">
              <img class="img-fluid" src="../../assets/proto/img/blog/sitecore-umbraco.jpg" />
              <h4
                class="font-weight-bold text-6 position-absolute bottom-0 left-0 z-index-2 ml-4 mb-4 pb-2 pl-2 pr-5 mr-5"
              >
                <router-link
                  class="text-color-light text-decoration-none"
                  to="/blog/posts/choosing-the-right-cms-for-your-enterprise"
                >Choosing the right CMS for your Enterprise</router-link>
              </h4>
            </header>
            <div class="card-body">
              <ul class="list list-unstyled custom-font-secondary pb-1 mb-2">
                <li class="list-inline-item line-height-1 mr-1 mb-0">APRIL 4, 2020</li>
                <li
                  class="d-inline-block list-inline-item vertical-divider border-color-dark px-2 line-height-1 mr-1"
                >0 COMMENTS</li>
                <li class="list-inline-item line-height-1 mb-0">MARCUS BAKER</li>
              </ul>
              <p
                class="custom-text-size-1 mb-2"
              >Over time organizations today can inherited multiple CMS (Content Management System) instances across distinct...</p>
              <router-link
                class="text-color-primary font-weight-bold text-decoration-underline custom-text-size-1"
                to="/blog/posts/choosing-the-right-cms-for-your-enterprise"
              >Read More...</router-link>
            </div>
          </article>
        </div>
        <div
          class="col-md-7 col-lg-4 px-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="250"
          style="animation-delay: 250ms;"
        >
          <article class="card custom-post-style-1 border-0">
            <header class="overlay overlay-show">
              <img class="img-fluid" src="../../assets/proto/img/blog/dotnet-showdown.jpg" />
              <h4
                class="font-weight-bold text-6 position-absolute bottom-0 left-0 z-index-2 ml-4 mb-4 pb-2 pl-2 pr-5 mr-5"
              >
                <router-link
                  class="text-color-light text-decoration-none"
                  to="/blog/posts/dotnet-cms-showdown"
                >.NET CMS Showdown</router-link>
              </h4>
            </header>
            <div class="card-body">
              <ul class="list list-unstyled custom-font-secondary pb-1 mb-2">
                <li class="list-inline-item line-height-1 mr-1 mb-0">APRIL 4, 2020</li>
                <li
                  class="d-inline-block list-inline-item vertical-divider border-color-dark px-2 line-height-1 mr-1"
                >0 COMMENTS</li>
                <li class="list-inline-item line-height-1 mb-0">MARCUS BAKER</li>
              </ul>
              <p
                class="custom-text-size-1 mb-2"
              >Choosing the right CMS is hard, I mean really hard, with the plethora of choices available in today’s market it....</p>
              <router-link
                class="text-color-primary font-weight-bold text-decoration-underline custom-text-size-1"
                to="/blog/posts/dotnet-cms-showdown"
              >Read More...</router-link>
            </div>
          </article>
        </div>
        <div
          class="col-md-7 col-lg-4 pl-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="650"
          style="animation-delay: 650ms;"
        >
          <article class="card custom-post-style-1 border-0">
            <header class="overlay overlay-show">
              <img class="img-fluid" src="../../assets/proto/img/blog/dev-sec-ops.jpg" />
              <h4
                class="font-weight-bold text-6 position-absolute bottom-0 left-0 z-index-2 ml-4 mb-4 pb-2 pl-2 pr-5 mr-5"
              >
                <router-link
                  class="text-color-light text-decoration-none"
                  to="/blog/posts/the-need-for-devsecops"
                >The need for DevSecOps</router-link>
              </h4>
            </header>
            <div class="card-body">
              <ul class="list list-unstyled custom-font-secondary pb-1 mb-2">
                <li class="list-inline-item line-height-1 mr-1 mb-0">MARCH 18, 2020</li>
                <li
                  class="d-inline-block list-inline-item vertical-divider border-color-dark px-2 line-height-1 mr-1"
                >0 COMMENTS</li>
                <li class="list-inline-item line-height-1 mb-0">MARCUS BAKER</li>
              </ul>
              <p
                class="custom-text-size-1 mb-2"
              >The introduction of security features in the beginning phase of application development reduces the risk...</p>
              <router-link
                class="text-color-primary font-weight-bold text-decoration-underline custom-text-size-1"
                to="/blog/posts/the-need-for-devsecops"
              >Read More...</router-link>
            </div>
          </article>
        </div>
      </div>
    </div>

    <section class="section section-height-3 bg-light border border-bottom-0 m-0">
      <div class="container">
        <div class="row">
          <div class="col">
            <div
              class="owl-carousel owl-theme mb-0"
              data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 7}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}"
            >
              <div>
                <img class="img-fluid opacity-2" src="../../assets/proto/img/logos/logo-1.png" alt />
              </div>
              <div>
                <img class="img-fluid opacity-2" src="../../assets/proto/img/logos/logo-2.png" alt />
              </div>
              <div>
                <img class="img-fluid opacity-2" src="../../assets/proto/img/logos/logo-3.png" alt />
              </div>
              <div>
                <img class="img-fluid opacity-2" src="../../assets/proto/img/logos/logo-5.png" alt />
              </div>
              <div>
                <img class="img-fluid opacity-2" src="../../assets/proto/img/logos/logo-2.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped>
.custom-post-style-1:hover .overlay:before {
  opacity: 0.2;
}

.cover{
  background-image: url('../../assets/proto/img/video_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
