

    import { Component, Vue } from 'vue-property-decorator';
    import SignIn from './sign-in.vue';
    import Data from '../services/data';

    @Component({
        components: {
            SignIn
        }
    })
    export default class TopNavigation extends Vue {

       public NavigationItems : Array<any> = Data.NavigationItems;

    }
