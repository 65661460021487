
    import { Action, Getter } from 'vuex-class';
    import { Component, Vue } from 'vue-property-decorator';
    const namespace: string = 'counter';

    @Component
    export default class SignIn extends Vue {


    }
